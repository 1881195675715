import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ExcelExport from "./ExcelExport";

class StyledTable extends React.Component {
  render() {
    // options for the employee results table (what to do onClick, sorting, etc.)
    var sortCaret = (order, column) => {
      if (!order) return;
      else if (order === "asc")
        return <i className="fa fa-fw text-dark text-right fa-caret-down"></i>;
      else if (order === "desc")
        return <i className="fa fa-fw text-dark text-right fa-caret-up"></i>;
      return null;
    };
    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total d-inline-block ml-1 mb-3">
        {from} to {to} of {size} Results
      </span>
    );
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="btn-group" role="group">
        {options.map(option => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn border ${isSelect ? "btn-info" : "btn-default"}`}
              style={{
                width: "50px",
                height: "40px",
                padding: "9px",
                fontSize: "100%",
                borderRadius: "0px"
              }}
            >
              <p style={{ fontSize: "14px" }}>{option.text}</p>
            </button>
          );
        })}
      </div>
    );
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = e => {
        e.preventDefault();
        onPageChange(page);
      };
      const activeStyle = {};
      activeStyle.padding = "10px";
      if (active) {
        activeStyle.backgroundColor = "#00a3e0";
        activeStyle.color = "white";
      } else {
        activeStyle.color = "black";
      }
      if (typeof page === "string") {
        activeStyle.color = "black";
      }
      return (
        <li className="page-item border">
          <a href="#" onClick={handleClick} style={activeStyle}>
            {page}
          </a>
        </li>
      );
    };
    const defaultSorted = [
      {
        dataField: this.props.defaultSort.length
          ? this.props.defaultSort
          : this.props.keyField,
        order: "asc"
      }
    ];
    var sizePerPageList = [];
    var length = this.props.results.length;
    if (length > 10) {
      sizePerPageList.push({
        text: "10",
        value: 10
      });
      if (length >= 25) {
        sizePerPageList.push({
          text: "25",
          value: 25
        });
        if (length >= 50) {
          sizePerPageList.push({
            text: "50",
            value: 50
          });
        }
      }
    }
    sizePerPageList.push({
      text: "All",
      value: length
    });
    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPageRenderer,
      firstPageText: "<<",
      prePageText: "<",
      nextPageText: ">",
      lastPageText: ">>",
      showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: sizePerPageList,
      pageButtonRenderer
    };
    const rowEvents = {
      onClick: this.props.handleRowClick
    };
    const percent = 100 / this.props.columns.length + "%";
    const columns = this.props.columns.map(item => {
      return {
        dataField: item.dataField,
        text: item.text,
        sort: item.sort,
        hidden: item.hidden === true ? true : false,
        sortCaret: sortCaret,
        headerStyle: (column, colIndex) => {
          return { width: percent, textAlign: "left" };
        }
      };
    });
    return (
      <div>
        {this.props.showExport && (
          <div className="row w-100 d-flex justify-content-between align-items-center m-0">
            <h5 className="text-info ml-3 mb-0">{this.props.header}</h5>
            <ExcelExport
              columns={
                this.props.excelColumns.length
                  ? this.props.excelColumns
                  : this.props.columns
              }
              data={
                this.props.excelData.length
                  ? this.props.excelData
                  : this.props.results
              }
              handleExportClick={this.props.handleExportClick}
            ></ExcelExport>
          </div>
        )}
        {this.props.results.length > 10 && (
          <BootstrapTable
            keyField={this.props.keyField}
            data={this.props.results}
            columns={columns}
            pagination={paginationFactory(options)}
            rowEvents={rowEvents}
            rowStyle={this.props.rowStyle}
            defaultSorted={defaultSorted}
            hover
          ></BootstrapTable>
        )}
        {this.props.results.length <= 10 && (
          <BootstrapTable
            keyField={this.props.keyField}
            data={this.props.results}
            columns={columns}
            rowEvents={rowEvents}
            rowStyle={this.props.rowStyle}
            defaultSorted={defaultSorted}
            hover
          ></BootstrapTable>
        )}
      </div>
    );
  }
}

StyledTable.defaultProps = {
  rowStyle: {},
  defaultSort: "",
  showExport: false,
  excelColumns: [],
  excelData: [],
  header: "",
  handleExportClick: function () {
    return;
  },
  handleRowClick: function () {
    return;
  }
};

StyledTable.propTypes = {
  results: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func,
  columns: PropTypes.array.isRequired,
  keyField: PropTypes.string.isRequired,
  defaultSort: PropTypes.string,
  showExport: PropTypes.bool,
  excelColumns: PropTypes.array,
  rowStyle: PropTypes.object,
  excelData: PropTypes.array,
  header: PropTypes.string,
  handleExportClick: PropTypes.func
};

export default StyledTable;
