// Export to Excel Ability
// CLEAN CODE

import React from "react";
import PropTypes from "prop-types";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExcelExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: []
    };
    this.handleClick = this.handleClick.bind(this);
    this.initialSetState = this.initialSetState.bind(this);
  }
  componentDidMount() {
    this.initialSetState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.initialSetState();
    }
  }

  initialSetState() {}

  handleClick() {}

  render() {
    var excelColumns = [];
    this.props.columns.forEach(item => {
      if (!item.hidden) {
        excelColumns.push(
          <ExcelColumn
            label={item.text}
            value={item.dataField}
            key={item.dataField}
          />
        );
      }
    });
    return (
      <ExcelFile
        element={
          <button
            className="btn text-info pl-3 pr-0 py-0 mr-3"
            onClick={this.props.handleExportClick}
          >
            Export <i className="fas fa-file-export"></i>
          </button>
        }
      >
        <ExcelSheet data={this.props.data} name="Identity Central Export">
          {excelColumns}
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

ExcelExport.defaultProps = {
  columns: [],
  data: [],
  handleExportClick: function () {
    return;
  }
};

ExcelExport.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleExportClick: PropTypes.func
};

export default ExcelExport;
