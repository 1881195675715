// https://github.com/ReactTraining/react-router/issues/1147#issuecomment-362964577

// What <Link> should always be
import React from "react";
import { NavLink } from "react-router-dom";

class ANavLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    // It is a simple element with nothing to link to
    const to = this.props.to;
    const children = this.props.children;
    const props = this.props;
    if (!to) return <span {...props}>{children}</span>;

    // It is intended to be an external link
    if (/^https?:\/\//.test(to))
      return (
        <a href={to} {...props}>
          {children}
        </a>
      );

    // Finally, it is an internal link
    return (
      <NavLink to={to} {...props}>
        {children}
      </NavLink>
    );
  }
}

export { ANavLink };
