import React from "react";
import touching_lives from "./touching_lives.svg";

class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer className="navbar navbar-dark bg-primary">
          <div className="navbar-brand mb-2">
            <img
              className="w-auto"
              src={touching_lives}
              alt="Touching lives, improving life."
            />
          </div>
          <div className="footer-primary right">
            {this.props.privacyPolicy && (
              <li>
                <a
                  href="https://pgone.sharepoint.com/sites/PrivacyCentral/Pages/employeepolicy.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  P&G Employee Privacy Policy
                </a>
              </li>
            )}
          </div>
          <div className="footer-primary links">
            <ul>
              <li>
                <span>{this.props.footerText}</span>
              </li>

              {this.props.email !== "" && (
                <li>
                  <a href={`mailto:${this.props.email}`}>Contact Us</a>
                </li>
              )}
            </ul>
          </div>
        </footer>
      </div>
    );
  }
}

Footer.defaultProps = {
  email: "",
  footerText: "For Internal Use Only",
  privacyPolicy: true
};

export default Footer;
