import * as React from 'react';

const NotAuthenticated = () => (
  <div>
    <div className="container">
      <h1>Authentification</h1>
      <p>Vous n'êtes pas authentifié.</p>
    </div>
  </div>
);

export default NotAuthenticated;
