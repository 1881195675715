import * as React from 'react';

const CallbackComponent = () => (
  <div>
    <div className="container">
      <h1>Authentification terminée</h1>
      <p>Vous allez être redirigé sur votre application.</p>
    </div>
  </div>
);

export default CallbackComponent;
