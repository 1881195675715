import * as React from "react";

const Authenticating = () => (
  <div>
    <div className="container">
      <p>Loading...</p>
    </div>
  </div>
);

export default Authenticating;
